import React, { useState, useCallback } from 'react';

import { useAuth, useShop, useLoading } from 'hooks';

import { FaCartPlus, FaCog } from 'react-icons/fa';
import { FaBoxArchive } from 'react-icons/fa6';

import { ShopContainer } from './styles';

import { SkinsNavigationItems, SkinsContentComponent } from './components';

import api from 'service';

export default function SkinManagement() {
  const { user } = useAuth();
  const { refreshSkins } = useShop();
  const { toggleLoading } = useLoading();

  const [currentMode, setMode] = useState('');
  const [filterBy, setFilterBy] = useState({});

  const handleMode = useCallback(
    (ev) => {
      const { mode } = ev.currentTarget.dataset;
      if (mode === currentMode) {
        setFilterBy({});
      }
      setMode((state) => {
        return state === mode ? '' : mode;
      });
    },
    [currentMode],
  );

  const handleWeapons = useCallback((ev) => {
    if (Object.keys(ev).includes('same')) {
      setFilterBy({});
    } else {
      setFilterBy(ev.currentTarget.dataset);
    }
  }, []);

  const handleCategories = useCallback(() => {
    setFilterBy({});
  }, []);

  const handleSave = useCallback(async (ev) => {
    toggleLoading();
    try {
      const send = new FormData();
      send.append('data', JSON.stringify(ev));
      await api
        .post('/shop/skin_manager.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(() => {
          refreshSkins();
        });
    } catch (error) {
      alert('Error!');
    } finally {
      toggleLoading();
    }
  }, []);

  return (
    <ShopContainer className="h-full flex flex-col flex-1 overflow-hidden">
      <div className="flex flex-row flex-1 overflow-hidden">
        <aside className=" w-64 h-full p-4 overflow-y-auto" style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}>
          <nav>
            <SkinsNavigationItems icon={FaBoxArchive} navLabel="Minhas Skins BNE" active={currentMode === 'inventory'} modeCallback={handleMode} modeRef="inventory" categoryCallback={handleCategories} weaponCallback={handleWeapons} />
            <SkinsNavigationItems icon={FaCartPlus} navLabel="BNE Shop" active={currentMode === 'shop'} modeCallback={handleMode} modeRef="shop" categoryCallback={handleCategories} weaponCallback={handleWeapons} />
            {user.isAdmin ? <SkinsNavigationItems icon={FaCog} navLabel="Gerenciar Skins" active={currentMode === 'manage'} modeCallback={handleMode} modeRef="manage" categoryCallback={handleCategories} weaponCallback={handleWeapons} /> : null}
          </nav>
        </aside>
        <SkinsContentComponent mode={currentMode} weapon={filterBy} saveCallback={handleSave} />
      </div>
    </ShopContainer>
  );
}
