import React from 'react';

import { useLoading } from 'hooks';

import { useSpring } from '@react-spring/web';
import { FullLoadingContainer } from './styles';

function FullLoading() {
  const { loading } = useLoading();

  const styledLoading = useSpring({
    opacity: loading ? 1 : 0,
    // zIndex: isLoading ? '10004' : '-1',
  });

  return (
    <FullLoadingContainer
      style={{ ...styledLoading, pointerEvents: loading ? 'all' : 'none' }}
    >
      <div>
        <span />
        <span />
        <span />
      </div>
    </FullLoadingContainer>
  );
}

export default FullLoading;
