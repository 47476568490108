import React from 'react';

import { Header } from '../Header';
import FullLoading from '../../Loading';

export function Container({ children }) {
  return (
    <div className="h-svh overflow-y-auto">
      <FullLoading />
      <Header />
      <div className="mb-10">{children}</div>
    </div>
  );
}
