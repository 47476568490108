import React, { useState } from 'react';

// import Header from '../Header';
import SideNav from '../SideNav';
import FullLoading from 'components/Loading';

import { Header } from 'components/Tailwind/Header';

export default function Container({ children, navigationItems, disableGoBack }) {
  const [goBackDisabled] = useState(disableGoBack);
  return (
    <div className="flex flex-col relative overflow-hidden w-screen h-screen select-none">
      <FullLoading />
      <Header hideBackButton={goBackDisabled} />
      <div className="flex flex-row" style={{ height: 'calc(100vh - 64px)' }}>
        <SideNav navigationItems={navigationItems} />
        <div className="overflow-y-auto w-full">{children}</div>
      </div>
    </div>
  );
}

Container.defaultProps = {
  navigationItems: [],
  disableGoBack: false,
};
