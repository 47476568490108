// TODO: move user stats to fetch ranking php
import React, { useCallback, createContext, useState, useEffect, useContext } from 'react';

import global from 'assets/global.png';
import supreme from 'assets/supreme.png';
import legendary from 'assets/legendary.png';
import guardian from 'assets/guardian.png';
import pink from 'assets/pink.png';
import gold from 'assets/gold.png';
import silver from 'assets/silver.png';

import api from 'service';

const RankingHook = createContext({});

const { REACT_APP_CURRENT_RANKING } = process.env;

function RankingProvider({ children }) {
  const [ranking, setRanking] = useState(() => {
    const locallyStored = localStorage.getItem(REACT_APP_CURRENT_RANKING);
    if (!locallyStored) {
      return [];
    }

    return JSON.parse(locallyStored);
  });

  const refreshRanks = useCallback(async () => {
    try {
      const stored = localStorage.getItem(REACT_APP_CURRENT_RANKING);

      const response = await api.get('/system/ranking.php');
      const mapped = response.map((item) => {
        let rank;

        switch (item.rank) {
          case 'gold':
            rank = gold;
            break;
          case 'guardian':
            rank = guardian;
            break;
          case 'legendary':
            rank = legendary;
            break;
          case 'pink':
            rank = pink;
            break;
          case 'supreme':
            rank = supreme;
            break;
          case 'global':
            rank = global;
            break;
          default:
            rank = silver;
            break;
        }
        // let rank = silver;

        // item.totalpoints >= 5000 && item.totalpoints < 10000 && (rank = gold);
        // item.totalpoints >= 10000 && item.totalpoints < 15000 && (rank = guardian);
        // item.totalpoints >= 15000 && item.totalpoints < 20000 && (rank = legendary);
        // item.totalpoints >= 20000 && item.totalpoints < 30000 && (rank = pink);
        // item.totalpoints >= 30000 && item.totalpoints < 50000 && (rank = supreme);
        // item.totalpoints >= 50000 && (rank = global);

        return { ...item, rank };
      });

      if (!stored || (!!stored && JSON.stringify(mapped) !== stored)) {
        localStorage.setItem(REACT_APP_CURRENT_RANKING, JSON.stringify(mapped));
        setRanking(mapped);
      }
    } catch (e) {
      // console.log(e);
    }
  }, []);

  useEffect(() => {
    refreshRanks();
    setInterval(() => {
      refreshRanks();
    }, 20 * 60_000);
  }, [refreshRanks]);

  return (
    <RankingHook.Provider
      value={{
        ranking,
        global,
        supreme,
        legendary,
        guardian,
        gold,
        silver,
      }}
    >
      {children}
    </RankingHook.Provider>
  );
}

function useRanking() {
  const context = useContext(RankingHook);

  if (!context) {
    throw new Error('useRanking must be used within and RankingProvider');
  }

  return context;
}

export { RankingProvider, useRanking };
