import React from 'react';

import { Container } from 'components';

import { FaRegGrinBeamSweat } from 'react-icons/fa';

function NotFound() {
  return (
    <Container>
      <div className="flex justify-center w-full h-full text-5xl text-zinc-400 gap-x-3 pt-10">
        <p>Essa página não existe</p>
        <FaRegGrinBeamSweat />
      </div>
    </Container>
  );
}

export default NotFound;
