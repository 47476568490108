import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from 'hooks';

import Routes from 'routes';

// import GlobalStyle from './styles';

import './styles/tailwind.css';

function App() {
  const titles = [`${process.env.REACT_APP_WEBSITE_NAME}`, `v${process.env.REACT_APP_VERSION}`];

  const [title, setTitle] = useState(0);
  useEffect(() => {
    document.title = titles[title];
    setTimeout(async () => {
      document.title = titles[title];
      setTitle((state) => (state >= titles.length - 1 ? 0 : state + 1));
    }, 2_000);
  }, [title]);

  return (
    <BrowserRouter basename={`${process.env.REACT_APP_DEFAULT_DIR}`}>
      {/* <GlobalStyle /> */}
      <AppProvider>
        <Routes />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
