import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';

const WindowHook = createContext({});

function WindowProvider({ children }) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const updateWidthAndHeight = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    updateWidthAndHeight();
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  }, [updateWidthAndHeight]);

  return (
    <WindowHook.Provider value={{ width, height }}>
      {children}
    </WindowHook.Provider>
  );
}

function useWindow() {
  const context = useContext(WindowHook);

  if (!context) {
    throw new Error('useWindow must be used within and WindowProvider');
  }

  return context;
}

export { WindowProvider, useWindow };
