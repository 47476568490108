import axios from 'axios';

// const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN}`);

const api = axios.create({});

// Add a request interceptor
api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // console.log('auth interceptor used');

    // const user = localStorage.getItem(process.env.REACT_APP_USER);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // console.table(response);
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // return response.data;
    return response;
  },
  function (error) {
    console.log(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export default api;
