import React, { useCallback, createContext, useState, useEffect, useContext } from 'react';

import api from 'service';

import { useAuth, useLoading } from './';

const ShopHook = createContext({});

function TierSelect({ tiers, changeCallback }) {
  const handleChange = useCallback(
    (ev) => {
      changeCallback(ev);
    },
    [changeCallback],
  );

  const optionClass = 'bg-stone-600 text-amber-400 checked:text-stone-600 checked:bg-amber-400 checked:font-medium hover:bg-green-300';

  return tiers.length > 0 ? (
    <div>
      <select className="rounded border-2 border-stone-500 text-stone-300 bg-transparent py-1 px-2" onChange={handleChange}>
        <option className={optionClass} value="">
          Selecione
        </option>
        {tiers.map((tier) => (
          <option className={optionClass} key={tier.tier} value={tier.tier}>
            {tier.label}
          </option>
        ))}
      </select>
    </div>
  ) : null;
}

function ShopProvider({ children }) {
  const categoryKey = process.env.REACT_APP_SHOP_CATEGORIES;
  const weaponsKey = process.env.REACT_APP_SHOP_WEAPONS;
  const { user } = useAuth();
  const { loading, toggleLoading } = useLoading();

  const [categories, setCategories] = useState(() => {
    const locallyStored = localStorage.getItem(categoryKey);
    if (!user || !locallyStored) {
      return [];
    }

    return JSON.parse(locallyStored);
  });

  const [weapons, setWeapons] = useState(() => {
    const locallyStored = localStorage.getItem(weaponsKey);
    if (!user || !locallyStored) {
      return [];
    }

    return JSON.parse(locallyStored);
  });

  const [skins, setSkins] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [inventory, setInventory] = useState([]);

  const refreshSkins = useCallback(async () => {
    try {
      !loading && toggleLoading();
      const response = await api.get('/shop/skins_data.php');
      const { xskins, xinventory } = response;
      setSkins(xskins);
      setInventory(xinventory);
    } catch (e) {
      // console.log(e);
    } finally {
      toggleLoading();
    }
  }, [loading, toggleLoading]);

  const getData = useCallback(async () => {
    const storedCategories = localStorage.getItem(categoryKey);
    const storedWeapons = localStorage.getItem(weaponsKey);
    const response = await api.get('/shop/skins_data.php');

    const { xtiers, xcategories, xweapons, xskins, xinventory } = response;

    if (!storedCategories || xcategories !== JSON.parse(storedCategories)) {
      setCategories(xcategories);
      localStorage.setItem(categoryKey, JSON.stringify(xcategories));
    }
    if (!storedWeapons || xweapons !== JSON.parse(storedWeapons)) {
      setWeapons(xweapons);
      localStorage.setItem(weaponsKey, JSON.stringify(xweapons));
    }

    setSkins(xskins);
    setTiers(xtiers);
    setInventory(xinventory);
  }, []);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  return (
    <ShopHook.Provider
      value={{
        categories,
        weapons,
        skins,
        tiers,
        inventory,
        refreshSkins,
        TierSelect,
      }}
    >
      {children}
    </ShopHook.Provider>
  );
}

function useShop() {
  const context = useContext(ShopHook);

  if (!context) {
    throw new Error('useShop must be used within and ShopProvider');
  }

  return context;
}

export { ShopProvider, useShop };
