import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import * as R from './routes';
import Container from 'components/Container';

function Routes() {
  return (
    <ReactRoutes>
      <Route path="*" element={<R.NotFound />} />
      <Route path="/" element={<R.Landing />} />
      <Route
        path="/home"
        element={
          <PrivateRoute
            outlet={
              <Container>
                <R.Home />
              </Container>
            }
          />
        }
      />
      <Route
        path="/skins"
        element={
          <PrivateRoute
            outlet={
              <Container>
                <R.SkinManagement />
              </Container>
            }
          />
        }
      />
      <Route path="/matches/:uuid" element={<R.Match />} />
      <Route
        path="/ranking"
        element={
          <Container>
            <R.Ranking />
          </Container>
        }
      />
      <Route path="/admin-panel" element={
        <PrivateRoute
            adminRoute
            outlet={
              <Container>
                <R.AdminPanel />
              </Container>
            }
        />
      }/>
    </ReactRoutes>
  );
}

export default Routes;
