import React, { createContext, useCallback, useContext } from 'react';

const LocalStorageContext = createContext({});

function LocalStorageProvider({ children }) {
  const setLocalStorage = useCallback((item, values) => {
    const localstorageitem = localStorage.getItem(item);

    if (localstorageitem) {
      const parsed = JSON.parse(localstorageitem);

      const updatedItem = {
        ...parsed,
        ...values,
      };

      localStorage.setItem(item, JSON.stringify(updatedItem));
    } else {
      localStorage.setItem(item, JSON.stringify(values));
    }
  }, []);

  const getLocalStorage = useCallback((item) => {
    const localStorageItem = localStorage.getItem(item);

    if (localStorageItem) {
      return JSON.parse(localStorageItem);
    }

    return {};
  }, []);

  const deleteLocalStorage = useCallback((item) => {
    localStorage.removeItem(item);
  }, []);

  const deleteLocalStorageItemKey = useCallback((item, values) => {
    const localStorageItem = localStorage.getItem(item);

    if (localStorageItem) {
      const stored = JSON.parse(localStorageItem);
      values.map((ocurr) => {
        delete stored[ocurr];
        return null;
      });
      localStorage.setItem(item, JSON.stringify(stored));
    }
  }, []);

  return (
    <LocalStorageContext.Provider
      value={{
        setLocalStorage,
        getLocalStorage,
        deleteLocalStorage,
        deleteLocalStorageItemKey,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
}

function useLocalStorage() {
  const context = useContext(LocalStorageContext);

  if (!context) {
    throw new Error(
      'useLocalStorage must be used within an LocalStorageProvider',
    );
  }

  return context;
}

export { LocalStorageProvider, useLocalStorage };
