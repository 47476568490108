import React from 'react';
import { MonthProvider, useMonth } from './month';
import { LocalStorageProvider, useLocalStorage } from './localStorage';
import { EncoderProvider, useEncoder } from './encoder';
import { LoadingProvider, useLoading } from './loading';
import { DownloadProvider, useDownload } from './download';
import { WindowProvider, useWindow } from './window';

import { AuthProvider, useAuth } from './auth';
import { ShopProvider, useShop } from './shop';
import { RankingProvider, useRanking } from './ranking';

function AppProvider({ children }) {
  return (
    <MonthProvider>
      <LocalStorageProvider>
        <EncoderProvider>
          <LoadingProvider>
            <DownloadProvider>
              <WindowProvider>
                <AuthProvider>
                  <RankingProvider>
                    <ShopProvider>{children}</ShopProvider>
                  </RankingProvider>
                </AuthProvider>
              </WindowProvider>
            </DownloadProvider>
          </LoadingProvider>
        </EncoderProvider>
      </LocalStorageProvider>
    </MonthProvider>
  );
}

export default AppProvider;
export { useMonth, useLocalStorage, useLoading, useEncoder, useDownload, useWindow, useAuth, useRanking, useShop };
