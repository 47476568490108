import React, { createContext, useContext, useState, useCallback } from 'react';

const LoadingHook = createContext({});

function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const toggleLoading = useCallback(() => {
    setLoading((state) => !state);
  }, []);

  return (
    <LoadingHook.Provider value={{ toggleLoading, loading }}>
      {children}
    </LoadingHook.Provider>
  );
}

function useLoading() {
  const context = useContext(LoadingHook);

  if (!context) {
    throw new Error('useLoading must be used within an LoadingHookProvider');
  }

  return context;
}
export { LoadingProvider, useLoading };
