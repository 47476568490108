import styled, { keyframes } from 'styled-components';
import { animated } from '@react-spring/web';
import { parseToRgb } from 'polished';

const rotate = keyframes`
0%{
  transform: rotate(0deg);
}
100%{
  transform: rotate(360deg);
}
`;

export const FullLoadingContainer = styled(animated.div)`
  --loading-primary: #fff;
  --loading-secondary: #03a9f4;
  --loading-secondary-red: ${parseToRgb('#03A9F4').red};
  --loading-secondary-green: ${parseToRgb('#03A9F4').green};
  --loading-secondary-blue: ${parseToRgb('#03A9F4').blue};

  /* --firstred: ${parseToRgb('#666').red};
  --firstgreen: ${parseToRgb('#666').green};
  --firstblue: ${parseToRgb('#666').blue};

   */
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10004;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  background: rgba(0, 0, 0, 0.75);

  div {
    width: 50px;
    height: 50px;
    border: 5px solid var(--loading-secondary);

    border-radius: 50%;
    border-right-color: transparent;
    filter: drop-shadow(0px 0px 5px var(--accent-color));
    /* box-shadow: -10px 0px 5px 5px
      rgba(
        var(--loading-secondary-red),
        var(--loading-secondary-red),
        var(--loading-secondary-red),
        0.8
      ); */

    animation: ${rotate} 1s linear infinite;
  }
`;
// export const FullLoadingContainer = styled(animated.div)`
//   --loading-primary: #fff;
//   --loading-primary-red: ${parseToRgb('#fff').red};
//   --loading-primary-green: ${parseToRgb('#fff').green};
//   --loading-primary-blue: ${parseToRgb('#fff').blue};

//   --loading-secondary: #fbc02d;
//   --loading-secondary-red: ${parseToRgb('#fbc02d').red};
//   --loading-secondary-green: ${parseToRgb('#fbc02d').green};
//   --loading-secondary-blue: ${parseToRgb('#fbc02d').blue};

//   position: absolute;
//   top: 50%;
//   left: 50%;
//   z-index: 10004;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   height: 100%;
//   transform: translate(-50%, -50%);

//   h3 {
//     color: var(--firstcolor);
//     margin: 0 0 20px 0;
//     font-size: 20px;
//   }

//   background: rgba(0, 0, 0, 0.75);

//   > div {
//     display: flex;

//     span {
//       display: flex;
//       width: 10px;
//       height: 10px;
//       border-radius: 50%;
//       border: 2px solid var(--loading-secondary);
//       box-shadow: 0px 0px 10px 2px
//         rgba(
//           var(--loading-primary-red),
//           var(--loading-primary-green),
//           var(--loading-primary-blue),
//           0.5
//         );

//       background-image: radial-gradient(
//         rgba(
//           var(--loading-primary-red),
//           var(--loading-primary-green),
//           var(--loading-primary-blue),
//           0.75
//         ),
//         rgba(
//           var(--loading-secondary-red),
//           var(--loading-secondary-green),
//           var(--loading-secondary-blue),
//           0.1
//         )
//       );

//       animation: ${loading} 2s infinite;

//       &:nth-child(1) {
//         /* animation-delay: 0.2s; */
//       }
//       &:nth-child(2) {
//         animation-delay: 0.2s;
//       }
//       &:nth-child(3) {
//         animation-delay: 0.4s;
//       }
//       & + span {
//         margin: 0 0 0 20px;
//       }
//     }
//   }
// `;
