import React, { useCallback, useEffect, useState } from 'react';

import { useAuth } from 'hooks';

import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { FaUser } from 'react-icons/fa';

import { useNavigate, useLocation } from 'react-router-dom';

import logo from '../../../assets/general/f50bc46c45a94eb0be58d2e71941949a.png';

export function Header() {
  const { user, signOut } = useAuth();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [navigation, setNavigation] = useState([
    { name: 'Home', href: 'home', current: false },
    // { name: 'Matches', href: 'matches', current: false },
    { name: 'Ranking', href: 'ranking', current: false },
    { name: 'Skins', href: 'skins', current: false },
  ]);

  useEffect(() => {
    setNavigation((state) => {
      return state.map((item) => {
        pathname.includes(item.href) && (item.current = true);
        !pathname.includes(item.href) && (item.current = false);
        return item;
      });
    });

    // console.log(navigation);
    // setNavigation((state) => {
    //   console.log(state.map((item) => item));
    // });
  }, [pathname]);

  const handleNavigate = useCallback((ev) => {
    const { path } = ev.currentTarget.dataset;
    navigate(`/${path}`, {});
  }, []);

  const handleLoginNavigation = useCallback(() => {
    const anchor = document.querySelector("a[id='auth']");

    if (anchor) {
      anchor.click();
    }
  }, [user, navigate]);

  return (
    <Disclosure as="nav" className="bg-zinc-800 border-b-2 border-sky-700 sticky top-0 z-50">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <img alt="" src={logo} className="h-8 w-auto" />
              <span className="text-white ml-2">Brasileiros na Europa</span>
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {!!user &&
                  navigation.map((item) => (
                    <button key={item.name} className={`duration-500 ${item.current ? 'bg-zinc-900 text-sky-300' : 'text-gray-300 hover:bg-zinc-700 hover:text-white'} rounded-md px-3 py-2 text-sm font-medium`} data-path={item.href} onClick={handleNavigate}>
                      {item.name}
                    </button>
                  ))}
              </div>
            </div>
          </div>
          {user ? (
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none ring-2 ring-sky-300 ring-offset-2 ring-offset-gray-800">
                    <span className="absolute -inset-1.5" />
                    {!!user && user.avatar !== null ? (
                      <img alt="" src={`${process.env.REACT_APP_DISCORD_CDN}/${user.discord}/${user.avatar}.${user.avatar.includes('a_') ? 'gif' : 'png'}`} className="h-8 w-8 rounded-full" />
                    ) : (
                      <span className="border-1 border-stone-300 ">
                        <FaUser className="h-8 w-8 rounded-full text-white" />
                      </span>
                    )}
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  {user.isAdmin ? (
                    <MenuItem>
                      <button href="#" className="w-full block text-left px-4 py-2 text-sm text-gray-500 hover:bg-zinc-300 hover:text-gray-600" style={{ transition: 'all 0.35s' }} data-path="admin-panel" onClick={handleNavigate}>
                        Admin Panel
                      </button>
                    </MenuItem>
                  ) : null}
                  <MenuItem>
                    <button href="#" className="w-full block text-left px-4 py-2 text-sm text-gray-500 hover:bg-zinc-300 hover:text-gray-600" style={{ transition: 'all 0.35s' }} onClick={signOut}>
                      Logout
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          ) : (
            <button onClick={handleLoginNavigation} className="duration-500 text-gray-300 bg-zinc-900 hover:bg-zinc-700 hover:text-sky-300 rounded-md px-3 py-2 text-sm font-medium">
              LOGIN
            </button>
          )}
        </div>

        <a id="auth" href={process.env.REACT_APP_DISCORD_OAUTH} style={{ display: 'none' }} />
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation
            .filter((item) => {
              if (!!user && user.isMember && user.steam) {
                return item;
              } else {
                return item.href === 'ranking';
              }
            })
            .map((item) => (
              <div key={item.name}>
                <button className={`${item.current ? 'bg-zinc-900 text-sky-300' : 'text-gray-300 hover:bg-zinc-700 hover:text-white'} rounded-md px-3 py-2 text-base font-medium w-full text-left`} data-path={item.href} onClick={handleNavigate}>
                  {item.name}
                </button>
              </div>
            ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}
