import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { addDays } from 'date-fns';

import { useEncoder, useLocalStorage, useLoading } from 'hooks';
// import { useEncoder, useToast, useChanges, useLoading } from 'hooks';
import externalAPI from 'service/auth';
import api from 'service';

import localStorageItems from './localStorageItems';

const AuthContext = createContext({});
function AuthProvider({ children }) {
  const { REACT_APP_API_DISCORD_GUILD, REACT_APP_TOKEN_ENDPOINT, REACT_APP_API_ENDPOINT } = process.env;
  const { toggleLoading } = useLoading();
  const { setLocalStorage, deleteLocalStorage } = useLocalStorage();
  const { et64, df64 } = useEncoder();
  const navigate = useNavigate();
  const guildid = REACT_APP_API_DISCORD_GUILD;
  const tokenEndpoint = REACT_APP_TOKEN_ENDPOINT;
  const baseEndpoint = REACT_APP_API_ENDPOINT;
  const user_endpoint_path = `users/@me`;
  const guild_info_endpoint_path = `users/@me/guilds/${guildid}/member`;

  const [data, setData] = useState(() => {
    const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN}`);
    const user = localStorage.getItem(`${process.env.REACT_APP_USER}`);

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${JSON.parse(token)}`;

      return { token: JSON.parse(token), user: JSON.parse(user) };
    }

    return {};
  });

  const retrieveLoginInfo = useCallback(async (userRef) => {
    const encoded = et64(JSON.stringify(userRef));
    await api
      .post(
        `/auth/login.php`,
        {},
        {
          headers: {
            encoded,
          },
        },
      )
      .then((result) => {
        deleteLocalStorage(`${process.env.REACT_APP_TOKEN}`);
        deleteLocalStorage(`${process.env.REACT_APP_USER}`);
        const baseData = {
          token: result.token,
          user: df64(result.user),
        };
        // console.table(baseData);
        api.defaults.headers.authorization = `Bearer ${baseData.token}`;
        setData({ ...baseData });

        setLocalStorage(`${process.env.REACT_APP_TOKEN}`, baseData.token);
        setLocalStorage(`${process.env.REACT_APP_USER}`, baseData.user);
      });
  }, []);

  const getCredentials = useCallback(async ({ code }) => {
    try {
      toggleLoading();
      const send = new FormData();
      send.append('grant_type', 'authorization_code');
      send.append('code', code);
      send.append('redirect_uri', process.env.REACT_APP_RETURN_URL);
      send.append('client_id', process.env.REACT_APP_DISCORD_CLIENT_ID);
      send.append('client_secret', process.env.REACT_APP_DISCORD_CLIENT_SECRET);

      const response = await externalAPI.post(`${tokenEndpoint}/token`, send, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      let token = {
        ...response.data,
        issued: new Date().getTime(),
        expiration: addDays(new Date().getTime(), 2).getTime(),
      };
      const { access_token, token_type } = token;

      let foundUser;
      let roles;
      try {
        await externalAPI
          .get(`${baseEndpoint}/${guild_info_endpoint_path}`, {
            headers: {
              // Authorization: `${token_type} ${access_token}`,
              Authorization: `${token_type} ${access_token}`,
            },
          })
          .then(async (result) => {
            roles = [...result.data.roles];
            foundUser = {
              ...result.data.user,
              roles,
              isMember: true,
              token,
            };
          });
      } catch (err) {
        const errCode = err.response.data.code;
        if (errCode === 10004) {
          await externalAPI
            .get(`${baseEndpoint}/${user_endpoint_path}`, {
              headers: {
                // Authorization: `${token_type} ${access_token}`,
                Authorization: `${token_type} ${access_token}`,
              },
            })
            .then((result) => {
              foundUser = { ...result.data, isMember: false, token };
            });
        }
      }

      await retrieveLoginInfo(foundUser);
    } catch (err) {
      // console.log(err);
    } finally {
      toggleLoading();
    }
  }, []);

  const signOut = useCallback(async () => {
    const send = new FormData();
    // console.log(data.user);
    send.append('token', data.user.access_token);
    send.append('client_id', process.env.REACT_APP_DISCORD_CLIENT_ID);
    send.append('client_secret', process.env.REACT_APP_DISCORD_CLIENT_SECRET);

    await externalAPI
      .post(`${tokenEndpoint}/token/revoke`, send, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(() => {
        localStorageItems.forEach((element) => {
          localStorage.removeItem(element);
        });

        setData({});

        navigate(`/`, { replace: true });
      });
  }, [navigate, data]);

  const checkMembership = useCallback(async () => {
    toggleLoading();
    let roles;
    let foundUser;
    try {
      await externalAPI
        .get(`${baseEndpoint}/${guild_info_endpoint_path}`, {
          headers: {
            // Authorization: `${token_type} ${access_token}`,
            Authorization: `Bearer ${data.user.access_token}`,
          },
        })
        .then(async (result) => {
          roles = [...result.data.roles];
          foundUser = {
            ...result.data.user,
            roles,
            isMember: true,
            token: {
              access_token: data.user.access_token,
              expiration: data.user.expiration,
              refresh_token: data.user.refresh_token,
            },
          };
        });

      retrieveLoginInfo(foundUser);
    } catch (e) {
      // console.log('not a member yet');
    } finally {
      toggleLoading();
    }
  }, []);

  const checkSteamID = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get('/system/checkSteamId.php');

      const { steam } = response;

      if (steam === 'not-present') {
        return;
      }
      // console.log(steam);

      let foundUser;
      let roles;
      await externalAPI
        .get(`${baseEndpoint}/${guild_info_endpoint_path}`, {
          headers: {
            Authorization: `Bearer ${data.user.access_token}`,
            // Authorization: `${token_type} LXEKFwr0tvpyS37UZZc5aL9N6uIfbX`,
          },
        })
        .then(async (result) => {
          roles = [...result.data.roles];
          foundUser = {
            ...result.data.user,
            roles,
            isMember: true,
            token: {
              access_token: data.user.access_token,
              expiration: data.user.expiration,
              refresh_token: data.user.refresh_token,
            },
          };

          //TODO: move to a generic function
        });
      // console.table(foundUser);
      await retrieveLoginInfo(foundUser);

      // const { user } = data;
      // setData((state) => ({ ...state, user: { ...state.user, steam } }));
      // setLocalStorage(`${process.env.REACT_APP_USER}`, { ...user, steam });

      //TODO: //getLoginMethod
      //TODO: get user from local storage and then update it.
    } catch (e) {
      console.error(e);
    } finally {
      toggleLoading();
    }
  }, [toggleLoading, data]);

  const refreshInfo = useCallback(() => {
    try {
      if (data.user) {
        if (!data.user.expiration) {
          throw new Error('Expired');
        }

        const now = new Date().getTime();
        if (data.user && now >= data.user.expiration) {
          signOut();
        }
      }
    } catch (e) {
      signOut();
    }
  }, [data.user]);

  useEffect(() => {
    refreshInfo();
  }, [refreshInfo]);

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        user: data.user,
        getCredentials,
        signOut,
        checkMembership,
        checkSteamID,
        refreshInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
