import React, { useCallback, useEffect, useState } from "react"

import api from 'service';

export default function AdminPanel() {
  const {REACT_APP_AKROS_USED_LICENSES } = process.env
  const [akrosLicenses, setAKROSLicenses] = useState(() => {
    const locallyStored = localStorage.getItem(REACT_APP_AKROS_USED_LICENSES);

    return locallyStored;
  });
  const getAKROSLicenses = useCallback(async () => {
    try {

      await api.get(`/external/getAkrosInfo.php`)
      .then(response => {
          setAKROSLicenses(response.playerCount);
          localStorage.setItem(REACT_APP_AKROS_USED_LICENSES, response.playerCount);
      })
      .catch(error => {
          console.error('Error:', error);
      });
    } catch(e){
      console.error(e);
    }
  })


  useEffect(() => {
    getAKROSLicenses();
  }, [])

  return (
    <div className="text-white w-full text-center">
      <div className="text-7xl mb-7">[WIP]</div>
      <span className="">AKROS USAGE:: {akrosLicenses}</span>
    </div>
  )
}
