import styled, { css } from 'styled-components';

export const ShopContainer = styled.div``;

export const WalletBalance = styled.div``;

export const ShopActions = styled.div``;

export const ShopContent = styled.div``;

export const SkinContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 250px;
  height: 250px;
  cursor: pointer;

  button {
    background-color: #546e7a;
  }

  &:hover {
    > div {
      border-color: var(--accent-color);
      background: rgba(255, 255, 255, 0.9);
    }
    img {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1.2);
      filter: drop-shadow(5px 5px 10px rgba(66, 66, 66, 1));
    }

    button {
      background-color: #33691e;
    }
  }

  ${(props) =>
    props.active &&
    css`
      > div {
        border-color: #2e7d32;
        background: rgba(165, 214, 167, 0.95);
      }
      img {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.2);
        filter: drop-shadow(5px 5px 10px rgba(66, 66, 66, 1));
      }

      &:hover {
        > div {
          border-color: #c53030;

          /* background: rgba(255, 138, 128, 0.25); */
          /* background: rgba(165, 214, 167, 0.65); */
          background: rgba(255, 255, 255, 0.4);
        }
        img {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
          filter: drop-shadow(0px 0px 0px #000000);
        }
      }
    `}
`;

export const SkinWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  border: 2px solid transparent;
  padding: 10px;
  position: relative;
  transition: all 0.35s;
  pointer-events: none;
  overflow: hidden;
`;

export const SkinImage = styled.img`
  --imgH: 90px;
  user-select: none;
  height: --var(imgH);
  max-height: calc(var(--imgH) * 1.2);
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 0.75;
  z-index: 0;
  filter: drop-shadow(0px 0px 0px #000000);
  transition: all 0.35s;
  pointer-events: none;
`;

export const SkinDescription = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;

  background: red;

  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(251, 192, 45, 1) 25%,
    rgba(251, 192, 45, 1) 50%,
    rgba(251, 192, 45, 1) 75%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const SkinTier = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #e6e6e6;

  position: absolute;
  top: 10px;
  right: -2px;
  padding: 0 10px 0 0;
  pointer-events: none;
  width: 100%;

  background: linear-gradient(
    90deg,
    rgba(251, 192, 45, 0) 0%,
    rgba(51, 46, 46, 1) 72%
  );
`;

export const Separator = styled.div`
  position: relative;
  display: flex;
  height: 30px;
  width: 90%;
  background-color: transparent;
  margin: 10px auto;

  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    border: 1px solid var(--accent-color);
    opacity: 0.75;
  }
`;
