import React, { useCallback } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

export default function SideNav({ navigationItems }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleNavigate = useCallback((ev) => {
    const { path } = ev.currentTarget.dataset;
    navigate(path, {});
  }, []);

  return (
    navigationItems.length > 0 && (
      <aside className="h-full w-64 p-4 overflow-y-auto" style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}>
        <nav>
          <ul className="space-y-2 text-zinc-300">
            {navigationItems.map((nav) => (
              <li key={nav.path}>
                <button data-path={nav.isChild ? `${pathname}${nav.path}` : nav.path} data-child={nav.isChild} className="flex items-center justify-between p-2 w-full hover:bg-zinc-700 hover:text-amber-400" onClick={handleNavigate}>
                  {nav.label}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
    )
  );
}
